



















































































































































import WeekdayLetter from '@/modules/cluster/filters/weekend-letter.filter';
import Day from '@/modules/common/types/day.type';
import { Inject } from 'inversify-props';
import _ from 'lodash';
import * as moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import LocationAvailabilityExpansionPanel from './location-availability-expansion-panel.vue';
import LocationAvailabilityService, { LocationAvailabilityServiceS } from '../location-availability.service';

@Component({
    filters: {
        WeekdayLetter,
    },
    components: {
        LoaderWrapper,
        LocationAvailabilityExpansionPanel,
    },
})
export default class LocationAvailabilityTable extends Vue {
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
    @Inject(LocationAvailabilityServiceS) private lAvailabilityService!: LocationAvailabilityService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    private tableHeight = '0px';
    private mainProvider = 'all';

    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.height;
        });
    }

    get isHistorical() {
        const currentDate = moment.utc({ year: this.documentFiltersService.year, month: this.documentFiltersService.month });
        const referenceDate = moment.utc({ year: 2025, month: 3 });
        return currentDate.isBefore(referenceDate, 'month');
    }

    get showAll() {
        return this.lAvailabilityFiltersService.showAll;
    }

    get pos() {
        return this.lAvailabilityFiltersService.pos;
    }

    @Watch('isClosedOnly')
    onClosedOnlyChange() {
        this.scrollTop();
    }

    @Watch('documents')
    onPosChange() {
        this.scrollTop();
    }

    availableDataSource(locationId: string, dataSource: string): boolean {
        const { providers } = this;
        if (!providers) {
            return false;
        }
        if (!this.lAvailabilityService.isDataSourceAvailable(locationId, dataSource.toLowerCase())) {
            return false;
        }
        let newProvider = [...providers, 'all'];
        if (dataSource.toLowerCase() === 'brand') {
            newProvider = providers;
        }
        return newProvider.some(provider => this.isAvailableProvider(locationId, dataSource, provider));
    }

    scrollTop() {
        const el: HTMLElement | null = document.querySelector('.locations-list');
        if (el) {
            el.scrollTo({ top: 0 });
        }
    }

    get height() {
        const el: HTMLElement | null = document.querySelector('.locations-list');
        const footer: HTMLElement | null = document.querySelector('footer');

        if (el && footer) {
            const table = el.getBoundingClientRect();
            const footerBox = footer.getBoundingClientRect();
            return `calc(100vh - ${table.top + footerBox.height + 35}px)`;
        }

        return '100vh';
    }

    get currentCompany() {
        return this.userService.currentCompany;
    }

    get documents() {
        return this.lAvailabilityService.documents;
    }

    get locations() {
        const { providers, documents, pickUpCityCodes } = this;
        if (!providers || !documents || !pickUpCityCodes) {
            return [];
        }
        const newProvider = [...providers, 'ALL'];
        const { currentPickupLocations } = this.lAvailabilityFiltersService;
        const { dataSources } = this;
        return currentPickupLocations.filter(location => Object.keys(this.documents!).includes(location.value)
            && this.pickUpCityCodes!.includes(location.value)
            && (!this.isClosedOnly || dataSources?.some(ds => this.availableDataSource(location.value, ds))));
    }

    get dataSources() {
        const { dataSources } = this.lAvailabilityFiltersService;
        return dataSources;
    }

    get providers() {
        const { providers } = this.lAvailabilityFiltersService;
        const filteredProviders = providers;
        if (filteredProviders && this.currentCompany && filteredProviders.some(provider => provider === this.currentCompany)) {
            const sortedProviders = filteredProviders.filter(provider => provider !== this.currentCompany);
            sortedProviders.unshift(this.currentCompany);
            return sortedProviders;
        }
        return filteredProviders;
    }

    get monthDays() {
        return this.documentFiltersService.days;
    }

    get closedDays() {
        return this.lAvailabilityService.closedDays;
    }

    get availableDays() {
        return this.lAvailabilityService.availableDays;
    }

    get unAvailableDays() {
        return this.lAvailabilityService.unAvailableDays;
    }

    get providerAvailablePerDataSource() {
        return this.lAvailabilityService.providerAvailablePerDataSource;
    }

    get isClosedOnly() {
        return this.lAvailabilityFiltersService.isClosedOnly;
    }

    get pickUpCityCodes() {
        return this.lAvailabilityFiltersService.pickUpCityCodes;
    }

    isAvailableProvider(location: string, dataSource: string, provider: string) {
        const { chainName } = this.userService;
        const { allowedVendorsPerCountry } = this.carsSharedService.filters;
        const country = this.lAvailabilityService.getCountryByPOS(this.pos || '');
        const unAvailableDaysCount = this.getUnAvailableDaysCount(location, dataSource.toLowerCase(), provider);
        const closedDaysCount = this.getClosedDaysCount(location, dataSource.toLowerCase(), provider);
        const isAllClosed = closedDaysCount === this.monthDays.length;
        const subDocument = _.get(this.documents, `${location}.${dataSource.toLowerCase()}.pickupDates`);
        const subDocumentDays = subDocument ? Object.keys(subDocument).length : this.monthDays.length;
        const isAllUnAvailable = unAvailableDaysCount === subDocumentDays;
        let isAllowByConfig = true;

        if (country && dataSource && ![this.mainProvider, chainName].includes(provider)) {
            const list = allowedVendorsPerCountry?.[country]?.[dataSource];
            if (list?.length && !list?.includes(provider)) {
                isAllowByConfig = false;
            } else if ((!list || list?.length === 0) && !this.providerAvailablePerDataSource[location][dataSource.toLowerCase()][provider]) {
                isAllowByConfig = false;
            }
        }
        return ((!this.isClosedOnly || (this.isClosedOnly && isAllClosed)) && !isAllUnAvailable) && isAllowByConfig;
    }

    isAvailableAllProvider(location: string, dataSource: string, provider: string) {
        if (provider === this.mainProvider && this.isClosedOnly) {
            return this.availableDataSource(location, dataSource);
        }

        return this.isAvailableProvider(location, dataSource, provider);
    }

    isMonday(day: Day) {
        return this.date(day).getDay() === 1;
    }

    isSunday(day: Day) {
        return this.date(day).getDay() === 0;
    }

    isToday(day: Day) {
        return this.documentFiltersService.isCurrentDay(day);
    }

    date(day: Day) {
        const { month, year } = this.documentFiltersService;
        return new Date(year, month, day);
    }

    getStatus(day: Day, locationId: string, dataSource: string, provider: string) {
        if (provider === this.mainProvider && this.isClosedOnly) {
            return 'unavailable';
        }
        const locationsAvailability = this.lAvailabilityService.getLocationsAvailability(locationId, dataSource, provider, String(day));
        const allLocationsAvailability = this.lAvailabilityService.getLocationsAvailability(locationId, dataSource, 'all', String(day));
        if (locationsAvailability === false) {
            return 'available';
        }
        if (locationsAvailability === true) {
            return 'unavailable';
        }

        if (allLocationsAvailability === false) {
            return 'unavailable';
        }
        if (allLocationsAvailability === true) {
            return 'unavailable';
        }

        return 'no-data';
    }

    getClosedDaysCount(locationId: string, dataSource: string, provider: string, fromTable: boolean = false) {
        if (provider === this.mainProvider && this.isClosedOnly && fromTable) {
            return this.monthDays.length;
        }
        const providerCount = _.get(this.closedDays, `${locationId}.${dataSource}.${provider}`, 0);
        return Number(providerCount);
    }

    getUnAvailableDaysCount(locationId: string, dataSource: string, provider: string) {
        return _.get(this.unAvailableDays, `${locationId}.${dataSource}.${provider}`, 0);
    }
}

