



































import { Inject } from 'inversify-props';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS } from '../location-availability-filters.service';

@Component
export default class LocationAvailabilityExpansionPanel extends Vue {
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;

    @Prop({ type: Boolean })
    public disabled!: boolean;

    @Prop({ type: Boolean, default: false })
    public isPanelExpanded!: boolean;

    public isExpanded: boolean = this.isPanelExpanded;

    get showAll() {
        return this.lAvailabilityFiltersService.showAll;
    }

    @Watch('isExpanded')
    onIsExpandedChanged() {
        this.recalculateHeightExpanded();
    }

    public mounted() {
        this.recalculateHeightExpanded();

        window.addEventListener('resize', this.recalculateHeightExpanded);
    }

    public beforeDestroy() {
        window.removeEventListener('resize', this.recalculateHeightExpanded);
    }

    private recalculateHeightExpanded() {
        const { body } = this.$refs as { body: HTMLDivElement };

        if (!body) return;

        body.style.transition = '0s';
        body.style.height = '0';
        body.style.height = `${body.scrollHeight}px`;
        body.style.transition = '';
    }

    public toggleExpand() {
        this.isExpanded = !this.isExpanded;
        this.$emit('update:isExpanded', this.isExpanded);
    }

    @Watch('showAll')
    onShowAll() {
        this.isExpanded = this.showAll;
    }
}
