










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';

@Component({
    components: { CustomMultiSelect },
})
export default class LocationAvailabilityProviderFilter extends Vue {
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;

    private mapProviders(providers: string[] | null, pos: string | null, country: string | null) {
        const filteredProviders = this.carsFiltersService.getFilteredProviders(providers, pos, country);
        return filteredProviders.map(value => ({
            name: value,
            value,
        }));
    }

    get currentValue() {
        const { providers, pos, country } = this.lAvailabilityFiltersService;
        if (!providers) {
            return [];
        }
        return providers.map(value => ({
            name: value,
            value,
        }));
    }

    set currentValue(value: { name: string, value: string }[]) {
        this.lAvailabilityFiltersService.providers = value.map(provider => provider.value);
    }

    get options() {
        const { providersList, pos, country } = this.lAvailabilityFiltersService;
        return providersList.map(value => ({
            name: value,
            value,
        }));
    }
}
