










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';

@Component({
    components: { CustomMultiSelect },
})
export default class LocationAvailabilityDataSourceFilter extends Vue {
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;

    get currentValue() {
        const { dataSources } = this.lAvailabilityFiltersService;
        if (!dataSources) {
            return [];
        }
        return dataSources.map(value => ({
            name: value,
            value,
        }));
    }

    set currentValue(value: { name: string, value: string }[]) {
        this.lAvailabilityFiltersService.dataSources = value.map(provider => provider.value);
    }

    get options() {
        const { dataSourcesList } = this.lAvailabilityFiltersService;
        if (!dataSourcesList) {
            return [];
        }
        return dataSourcesList.map(value => ({
            name: value,
            value,
        }));
    }
}
